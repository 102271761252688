import React from 'react';
import './post-label.less';
import goodIssue from 'components/assets-image/common-icon/good_issue.png';
import PropTypes from 'prop-types';

export const PostLabel = props => (
  <>
    {props.is_spam && <span className="post-label__span">黑名单</span>}
    {props.is_notice && <span className="post-label__span">公告</span>}
    {props.is_good && <span className="post-label__span label_good"><img src={goodIssue} alt="好问题" /></span>}
    {props.is_hidden && <span className="post-label__span">隐藏</span>}
    {props.is_top && <span className="post-label__span">置顶</span>}
  </>
);


PostLabel.defaultProps = {
  is_top: false,
  is_hidden: false,
  is_spam: false,
  is_notice: false,
  is_good: false,
};

PostLabel.propTypes = {
  is_top: PropTypes.bool,
  is_hidden: PropTypes.bool,
  is_spam: PropTypes.bool,
  is_notice: PropTypes.bool,
  is_good: PropTypes.bool,
};
