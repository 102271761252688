import React from 'react';
import { clipboardText } from '@tencent/tc-clipboard';
import { TcIconTapdLine } from 'components/IconSystem';
import PropTypes from 'prop-types';

export const BtnTapd = (props) => (
  <div className="btn btn-tapd" tabIndex={0} role="button" onClick={props.onClick} onKeyPress={props.onClick}>
    <TcIconTapdLine style={{ fill: '#fff' }} />
    <b className="btn-text">TAPD</b>
  </div>
);

BtnTapd.propTypes = {
  onClick: BtnTapd.func,
};

BtnTapd.defaultProps = {
  onClick: () => console.log('onClick 未绑定'),
};
