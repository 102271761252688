import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { post_api_tip_callback } from 'components/api';
import { Mask } from 'components/mask';
import { TippedContent } from './tipped-content';

import { processPostApiTip, processCallWxPay } from './common';

import './tipped-modal.less';

export class TippedModal extends Component {

    constructor(props) {
        super(props);

        this.processCheckPayStatus = this.processCheckPayStatus.bind(this);
    }

    /**
     * 点击确认金额后，开始支付流程
     * @param value     金额，单位元
     */
    onConfirm(value) {

        const { props, state } = this;

        const params = {
            productId: props.productId,
            postId: props.postId,
            amount: (value * 100).toFixed(0)
        };

        Promise.resolve(params)
            .then(processPostApiTip)
            .then(processCallWxPay)
            .then(this.processCheckPayStatus)
            .catch(error => props.onError(error));
    }

    /**
     * 请求业务后台，二次确认该订单是否已经支付
     * @param data  订单数据
     */
    processCheckPayStatus(data) {

        const { state, props } = this;

        const params = {
            productId: props.productId,
            tipId: data.id
        };

        return new Promise(function(resolve, reject) {
            post_api_tip_callback(params).then(function(resp) {

                if (resp.status !== 0) {
                    return reject(new Error(resp.message));
                }

                props.onSuccess();

            });
        });

    }

    render() {

        const { props, state } = this;

        return createPortal((
            <div className={classNames('tipped-modal', { active: props.isActive })}>
                {props.isActive && <Mask onClick={props.onCancel} />}

                {props.isActive && (
                    <TippedContent
                        onConfirmClick={value => this.onConfirm(value)}
                        avatar={props.avatar_url}
                        nickName={props.nick_name}
                        onClose={props.onCancel}
                    />
                )}

            </div>
        ), document.body);
    }
}

TippedModal.propTypes = {
    isActive: PropTypes.bool,
    nick_name: PropTypes.string,
    avatar_url: PropTypes.string,
    productId: PropTypes.string,
    postId: PropTypes.string,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
};

TippedModal.defaultProps = {
    onError: () => console.log('onError 未绑定'),
    onSuccess: () => console.log('onSuccess 未绑定'),
    onCancel: () => console.log('onCancel 未绑定')
};
