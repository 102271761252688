/* eslint-disable camelcase, no-useless-escape */
import xss from 'xss';
import { forEach } from 'lodash-es';
import { href_link_jump } from 'components/href-helper';
import { getProductId } from 'components/util';

const expressionWords = '[微笑][撇嘴][色][发呆][得意][流泪][害羞][闭嘴][睡][大哭][尴尬][发怒][调皮][呲牙][惊讶][难过][酷][冷汗][抓狂][吐][偷笑][愉快][白眼][傲慢][饥饿][困][惊恐][流汗][憨笑][悠闲][奋斗][咒骂][疑问][嘘][晕][疯了][衰][骷髅][敲打][再见][擦汗][抠鼻][鼓掌][糗大了][坏笑][左哼哼][右哼哼][哈欠][鄙视][委屈][快哭了][阴险][亲亲][吓][可怜][菜刀][西瓜][啤酒][篮球][乒乓][咖啡][饭][猪头][玫瑰][凋谢][嘴唇][爱心][心碎][蛋糕][闪电][炸弹][刀][足球][瓢虫][便便][月亮][太阳][礼物][拥抱][强][弱][握手][胜利][抱拳][勾引][拳头][差劲][爱你][NO][OK][爱情][飞吻][跳跳][发抖][怄火][转圈][磕头][回头][跳绳][投降]';
// http://tapd.oa.com/10117011/bugtrace/bugs/view?bug_id=1010117011056684189&url_cache_key=8eddc61366ed4bd8807c273dc0f252a2
// 避开带"[]"的字符带来无匹配表情的问题
const expressionRegExp = /\[(\d|\D)*?\]/g;
const expressionItems = expressionWords.match(expressionRegExp);
const expressionList = [];
const expressionMap = {};

expressionItems.forEach((item, index) => {
  const config = {
    msg: item,
    index,
    clazz: `expression-${index + 1}-2x`,
    src: `./img/Expression_${index + 1}@2x.png`,
  };
  expressionList.push(config);
  expressionMap[item] = config;
});

export const EXPRESSION_CONFIG = {
  REG_EXP: expressionRegExp,
  LIST: expressionList,
  MAP: expressionMap,
};

/**
 * 将 \n 换成 br 表情
 * @param text
 */
export const revertBr = (text = '', newLineTag = 'br') => {
  const placeVale = newLineTag === 'br' ? '<br />' : `<${newLineTag}></${newLineTag}>`;
  return text.replace(/\n/g, placeVale);
};

/**
 * 长文本中的任意 [表情] 转换成 <i></i>
 * @param text
 * @return {string}
 */
export const revertWordToImage = (text = '') => {
  let result = text;

  // 正则 [中文]
  // 这里的正则匹配加上 \w 英文字符，匹配[ok][no] 之类的表情
  const regex = /\[[\w\u4E00-\u9FA5]+\]/g;

  result = result.replace(regex, match => revertEmojiTextToImage(match));

  return result;
};
/**
 * 通过表情文字获取表情位序
 * @param {string} text
 * @return {number}
 */
export const getEmojiIndexByText = (text = '') => {
  const EXPRESSION_ITEM = EXPRESSION_CONFIG.MAP[text];
  if (!EXPRESSION_ITEM) {
    console.log(`不是合法表情:${text}`);
    return 0;
  }
  return EXPRESSION_ITEM.index + 1;
};
/**
 * 单一表情文字转图片 [哭] => <img />
 * @param text
 * @return {string}
 */
export const revertEmojiTextToImage = (text = '') => {
  const EXPRESSION_ITEM = EXPRESSION_CONFIG.MAP[text];

  if (!EXPRESSION_ITEM) {
    console.log(`不是合法表情:${text}`);
    return text;
  }
  if (process.env.TARO_ENV === 'weapp') {
    return `<img title="${text}" alt="${text}" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="Expression_${EXPRESSION_ITEM.index + 1}"/>`;
  }

  const img = document.createElement('img');
  // 1像素的透明图片
  img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
  img.className = `Expression_${EXPRESSION_ITEM.index + 1}`;
  img.alt = text;
  img.title = text;
  return img.outerHTML;
};

/**
 * 转换 url 字符串为一个 a 标签
 * @param text
 * @param productId 产品id
 * @return {*}
 */
export const enhanceLink = (text) => {
  // TODO 找产品确认小程序对页面超链接该咋处理
  if (process.env.TARO_ENV === 'weapp') {
    return text;
  }

  // protocol + hostname + pathname + search
  const regex = /https?:\/\/[\w\.\/:\-=#!]+(\?[\w=&;#!\.\-]+)?/g;
  const result = text.replace(regex, match => `<a href="${href_link_jump(match)}"  class="goto-link">${match}</a>`);

  return result;
};

/**
 * xss 过滤模块
 * @param string        原始 html 字符串
 * @return {string}     安全的 html 字符串，可以直接放入 dangerouslySetInnerHTML 中使用
 */
export const xssFilter = string => xss(string, {
  whiteList: {
    a: ['target', 'href', 'title', 'class'],
    p: ['style'],
    span: ['style'],
    div: ['style'],
    pre: ['style'],
    u: ['style'],
    br: [],
    em: [],
    ol: [],
    ul: [],
    li: [],
    strong: [],
    img: ['class', 'height', 'width', 'alt', 'title'],
    i: ['class'],
    figure: ['class', 'style'],
    figcaption: [],
    blockquote: [],
    h1: [],
    h2: [],
    h3: [],
    h4: [],
    h5: [],
    h6: [],
    table: ['style'],
    tbody: [],
    thead: [],
    tr: [],
    td: [],
    th: ['scope'],
  },
  stripIgnoreTagBody: true,
  allowCommentTag: false,
  onIgnoreTagAttr(tag, name, value, isWhiteAttr) {
    if (tag === 'img' && name === 'src') {
      // 默认策略
      // https://github.com/leizongmin/js-xss/blob/master/lib/default.js#L151
      const defaultRule = [/^https?:\/\//, /^mailto:/, /^tel:/, /^#/, /^\//];

      // 默认会过滤 base64，我们需要支持特定 base64 内容
      const rule = defaultRule.concat([
        /^data:image\/gif;base64,R0lGODlhAQABAIAAAAAAAP\/\/\/yH5BAEAAAAALAAAAAABAAEAAAIBRAA7/,
      ]);

      const res = rule.find(regex => regex.test(value));
      return res ? `${name}="${value}"` : '';
    }
  },
});

/**
 * 富文本渲染
 * @param content
 * @param productId 产品id
 * @param newLineTag 换行标签替换
 * @param renderLink 是否渲染链接
 * @return {{__html: (*|string)}}
 */
export const dangerouslySetInnerHTML = (content, productId = '', newLineTag = 'br', renderLink = true) => {
  // [表情] 转 <img />
  let html = revertWordToImage(content);

  // \n 转 <br />
  html = revertBr(html, newLineTag);
  // url 文本转 <a /> 标签
  if (renderLink) html = enhanceLink(html);

  // xss 防御
  html = xssFilter(html);

  return {
    __html: html,
  };
};

/**
 * 提取html片段中的文本字符串
 * @param {string} content 内容
 * @param {boolean} nowrap 是否换行
 * @returns
 */
export const convertHtmlToPlainText = (content, nowrap = true) => {
  const { __html } = dangerouslySetInnerHTML(content);
  const el = document.createElement('div');
  el.innerHTML = __html;
  return nowrap ? el.innerText.replace(/\n/g, '') : el.innerText;
};

/**
 * 替换字符串中 <a> 标签的 href
 * @param content
 * @returns {string}
 */
export const replaceAnchorHref = (content) => {
  const html = xssFilter(content);

  // TODO 小程序后续处理需要补上
  if (process.env.TARO_ENV === 'weapp') {
    return html;
  }
  const div = document.createElement('div');

  div.innerHTML = html;

  // 替换 DOM 中 a 标签的 href 属性
  // querySelectorAll 返回的 NodeList 在 Android 5 还不支持 forEach
  forEach(div.querySelectorAll('a'), (anchor) => {
    anchor.href = href_link_jump(anchor.href);
  });
  return div.innerHTML;
};
