import React, { useState } from 'react';
import { ImageAlbum } from '@tencent/coral-design';
/**
 * 兔小巢富文本图片预览组件
 */
const PreviewImage = ({ children, content }) => {
  const [showPreviewImg, setShowPreviewImg] = useState(false);
  const [previewImgSrc, setPreviewImgSrc] = useState('');
  // 提取图片
  const images:string[] = content.match(/<img(.*?)>/g);
  if (!images) {
    return children;
  }
  // 提取src
  const srcList = images.map((str) => {
    const srcArr = str.match(/src=['"]?([^'"]*)['"]?/i);
    if (!srcArr) {
      // TODO 图片加载失败的默认图片
      return '';
    }
    return srcArr[1].replace(/&amp;/g, '&');
  });

  // 子节点被点击时代理点击事件， 如果是图片被点击，打开图片放大组件
  const handleClick = (e) => {
    if (
      !e.target
      || !e.target.src
      || e.target.nodeName !== 'IMG'
    ) {
      return;
    }

    setShowPreviewImg(true);
    setPreviewImgSrc(e.target.src);
  };

  // 关闭图片预览
  const onBrowserImageClose = () => {
    setShowPreviewImg(false);
    setPreviewImgSrc('');
  };

  let showIndex = srcList.findIndex(item => item === previewImgSrc);
  (showIndex === -1) && (showIndex = 0);

  return (
    <div
      className="preview-image-pc"
      onClick={handleClick}
      role="article"
    >
      {showPreviewImg && (
        <ImageAlbum
          imageList={srcList}
          previewList={srcList}
          showPreview={true}
          showIndex={showIndex}
          onClose={onBrowserImageClose}
        />
      )}
      {children}
    </div>
  );
};

export default PreviewImage;
