import React, { useState } from 'react';
import Tag from 'components/tag';
import './good-question-popup.less';

const GoodQuestionPopup = (props) => {

    return (
        <Tag.div className={`good-question-popup ${props.isPc ? 'pc-good-question' : 'mobile-good-question'}`}>
            <Tag.div className="title">标记好问题，沉淀好想法</Tag.div>
            <Tag.div className="content">
                <Tag.p>把好的帖子标为「好问题」，除了会有特别角标，还会获得更靠前的展示机会。</Tag.p>
            </Tag.div>
            <Tag.div className="is-know" onClick={props.onClose}>知道了</Tag.div>
        </Tag.div>
    );
};

GoodQuestionPopup.defaultProps = {
    isPc: false,
    onClose: () => { }
};

export { GoodQuestionPopup };