/**
 * Created by v_sameli on 2018/6/22.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconZanShang, TcIconRotate  } from 'components/IconSystem';
import { Avatar } from 'components/user';

import { getRandomInt } from './common';
import MotionNumber from './motion-number';

export const TippedContent = (props) => {

    const [amount, setAmount] = useState(getRandomInt);

    return (
        <div className="tip-modal-content">

            <div className="content-inside">
                <a className="close-tag" onClick={props.onClose}></a>
                <header>
                    <IconZanShang />
                </header>
                <img className="avatar" src={props.avatar} />
                <div className="nickname">
                    {props.nickName}
                </div>
                <div className="amount">
                    <MotionNumber to={amount[0]} />
                    <span>.</span>
                    <MotionNumber to={amount[2]} />
                    <MotionNumber to={amount[3]} />
                </div>
                <a className="change-amount" onClick={event => setAmount(getRandomInt())}>
                    <TcIconRotate />
                    <span>换个金额</span>
                </a>
                <i className="gap" />
                <footer>
                    <a className="wrap" data-disable={props.disable} onClick={props.onConfirmClick.bind(this, amount)}>
                        确认金额
                    </a>
                </footer>
            </div>
        </div>
    );

};

TippedContent.propTypes = {
    nickName: PropTypes.string,
    avatar: PropTypes.string,
    disable: PropTypes.bool,
    onConfirmClick: PropTypes.func
};
