import React, { FC, Fragment, useState } from 'react';
import classnames from 'classnames';
import Tag from 'components/tag';
// import { Loading } from 'components/loading';
import './index.less';
import { formatThousandsNumber } from 'components/util/number';

interface SupportBtnProps {
  /** 是否已赞 | 受控 */
  isLiked: boolean;
  onClick: () => void;
  count: number;
  /** 按钮类型 */
  type: TYPE;
  /** 0赞时文案 */
  zeroText?: string;
  /** 是否在加载中 */
  isLoading?: boolean;
  /** 是否禁用 */
  disabled?: boolean;
}
enum TYPE {
  /** 兔小巢移动端首页 */
  MOBILE = 'mobile',
  /** 兔小巢移动端帖子详情页 */
  MOBILE_DETAIL = 'mobile-detail',
  /** CE 移动端首页 */
  MOBILE_CE = 'mobile-ce',
  /** 兔小巢PC端首页/详情页 */
  PC = 'pc',
  /** CE PC端首页 */
  PC_CE = 'pc-ce',
  /** roadmap  PC端 */
  PC_ROADMAP = 'pc-roadmap',
  /** roadmap  移动端 */
  MOBILE_ROADMAP = 'mobile-roadmap',
}
const cls = 'support-btn';

/**
 * 支持按钮，顶操作，和点赞区分开
 */
const SupportBtn: FC<SupportBtnProps> = ({
  isLiked,
  onClick,
  count,
  type = TYPE.MOBILE,
  zeroText = '赞同',
  // isLoading,
  disabled,
}) => {
  const [isHover, setIsHover] = useState(false);
  const countText = count === 0 ? zeroText : formatThousandsNumber(count);

  const handleMouseEnter = () => {
    if (type === TYPE.PC_ROADMAP && !isLiked) {
      setIsHover(true);
    }
  };

  const handleMouseLeave = () => {
    if (type !== TYPE.PC_ROADMAP) return;
    setIsHover(false);
  };
  return (
    <Tag.div
      className={classnames(cls, `${cls}--${type}`, {
        [`${cls}--active`]: isLiked,
        // [`${cls}--loading`]: isLoading,
        [`${cls}--disabled`]: disabled,
      })}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Fragment>
        <Tag.div className={`${cls}__triangle`} />
        {isHover ? zeroText : countText}
      </Fragment>

    </Tag.div>
  );
};

export { TYPE };
export default SupportBtn;
