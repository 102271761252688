import { post_api_tip } from 'components/api';

export const RANDOM_LIST = [
    '1.20',
    '1.68',
    '1.88',
    '2.88',
    '3.65',
    '5.20',
    '6.66',
    '8.88',
    '9.99',
];

export const endpoint = {
    'MP': 'MP',         // 小程序
    'PC': 'PC',         // PC
    'H5': 'H5',         // 非微信的移动端
    'PUBLIC': 'PUBLIC'  // 微信移动客户端
};

export const getRandomInt = function () {
    const min = Math.ceil(0);
    const max = Math.floor(RANDOM_LIST.length);
    const idx = Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive

    return RANDOM_LIST[idx];
};

/**
 * 微信 5.0 才开始支持微信支付接口
 * @return {boolean}
 */
export const isMoreThenMicroMessenger5 = function() {

    const res = window.navigator.userAgent.match(/micromessenger\/(\d+)/i);

    if (res && res[1]) {
        return Boolean(res[1] >= 5);
    }

    return false;

};

/**
 * 生成微信字符订单
 * @param params
 * @return {Promise<T | never>}
 */
export const processPostApiTip = function (params) {
    return post_api_tip(params).then(resp => {
        if (resp.status !== 0) {
            return Promise.reject(new Error(resp.message));
        }

        return resp.data;
    });
};

/**
 * 非微信客户端，调用 H5 的支付界面
 * @param data
 * @return {Promise<any>}
 */
export const processCallWebPay = function(data) {
    // 非 H5 的支付方式，交给后面处理
    if (data.endpoint !== endpoint.H5) {
        return Promise.resolve(data);
    }

    console.log('H5 版本的打赏流程开发中');

    return new Promise(function (resolve, reject) {

        if (!data.redirect_url) {
            return reject(new Error('redirect_url 获取失败'));
        }

        const windowReference = window.open(data.redirect_url);

        const timer = setInterval(function() {
            if (windowReference.closed) {
                clearInterval(timer);
                resolve(data);
            }
        }, 500);

    });

};

/**
 * PC 端扫码支付流程
 * @param data
 * @return {Promise<never>|{code_url}|*}
 */
export const processCallPCPay = function (data) {

    // 非 PC 的支付方式，交给后面处理
    if (data.endpoint !== endpoint.PC) {
        return Promise.reject(new Error(`endpoint 不正确: ${data.endpoint}`));
    }


    if (!data.code_url) {
        return Promise.reject(new Error('获取 code_url 失败'));
    }

    return data;
};

/**
 * 微信客户端内，调用 WeixinJSBridge 的支付接口
 * @param data
 * @return {Promise<never>|Promise<any>}
 */
export const processCallWxPay = function (data) {

    if (data.endpoint !== endpoint.PUBLIC) {
        return Promise.resolve(data);
    }

    if (!isMoreThenMicroMessenger5()) {
        return Promise.reject(new Error('当前微信版本太低，不支持打赏'));
    }

    const params = {
        'appId': String(data.appId),
        'timeStamp': String(data.timeStamp),
        'nonceStr': data.nonceStr,
        'signType': data.signType,
        'paySign': data.paySign,
        'package': data.package
    };

    return new Promise(function(resolve, reject) {

        const onBridgeReady = () => {
            // 微信客户端全局注入的对象
            // eslint-disable-next-line no-undef
            WeixinJSBridge.invoke('getBrandWCPayRequest', params, function(res) {

                switch (res.err_msg) {
                    case 'get_brand_wcpay_request:ok':
                        resolve(data);
                        break;
                    case 'get_brand_wcpay_request:cancel':
                        reject(new Error('取消支付'));
                        break;
                    case 'get_brand_wcpay_request:fail':
                        reject(new Error('支付失败'));
                        break;
                    default:
                        reject(new Error(`未知错误：${res.err_msg}`));
                        break;

                }

            });
        };

        if (typeof WeixinJSBridge === 'undefined') {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else {
            onBridgeReady();
        }

    });

};
