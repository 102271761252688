import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/modal';
import { post_api_tip_callback } from 'components/api';
import { processPostApiTip, processCallPCPay } from './common';
import { TippedContent } from './tipped-content';
import { TippedQrcode } from './tipped-qrcode';

import './desktop-tipped-modal.less';

/**
 * PC 端的赞赏逻辑
 */
export class DesktopTippedModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            code_url: ''
        };

        this.processShowQRCode = this.processShowQRCode.bind(this);
        this.processCheckPayStatus = this.processCheckPayStatus.bind(this);
    }

    onConfirm(value) {

        const { props, state } = this;

        const params = {
            productId: props.productId,
            postId: props.postId,
            amount: (value * 100).toFixed(0),
            endpoint: 'PC'
        };

        Promise.resolve(params)
            .then(processPostApiTip)
            .then(processCallPCPay)
            .then(this.processShowQRCode)
            .then(data => this.processCheckPayStatus(data))
            .catch(error => props.onError(error));

    }

    processShowQRCode(data) {

        if (!data.code_url) {
            return Promise.reject(new Error('code_url 获取失败'));
        }

        this.setState({ code_url: data.code_url });

        return data;
    }

    /**
     * 请求业务后台，二次确认该订单是否已经支付
     * @param data  订单数据
     */
    processCheckPayStatus(data) {

        const { props } = this;

        const params = {
            productId: props.productId,
            tipId: data.id
        };

        const delay = 500;

        const onSuccess = (json) => {

            if (this.willUnmount) {
                return;
            }

            if (json.data.status !== 1) {
                props.onSuccess();
                return;
            }

            this.timer = setTimeout(() => {
                this.processCheckPayStatus(data);
            }, delay);

        };

        post_api_tip_callback(params).then(onSuccess);
    }

    // 关闭打赏弹窗
    onCloseReward() {
        const { props } = this;
        clearTimeout(this.timer); // 清楚请求订单的轮询 timer
        props.onClose();
    }
    render() {

        const { props, state } = this;

        return (
            <Modal onClose={() => this.onCloseReward()}>
                <div className="desktop-tipped-modal">

                    {state.code_url
                        ? (
                            <TippedQrcode url={state.code_url} />
                        )
                        : (
                            <TippedContent
                                avatar={props.avatar}
                                nickName={props.nickName}
                                onConfirmClick={this.onConfirm.bind(this)}
                            />
                        )
                    }

                </div>
            </Modal>
        );
    }
}

DesktopTippedModal.propTypes = {
    productId: PropTypes.string,
    postId: PropTypes.string,
    avatar: PropTypes.string,
    nickName: PropTypes.string,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onEndPay: PropTypes.func,   // 用户关闭打赏弹窗后，结束 this.processCheckPayStatus 调用
    onClose: PropTypes.func,
};

DesktopTippedModal.defaultProps = {
    onClose: () => console.log('DesktopTippedModal 没有onClose')
};
