import React from 'react';
import PropTypes from 'prop-types';
import Tag from 'components/tag';
import classNames from 'classnames';

import { TcIconTick } from 'components/IconSystem';

export const BtnPublic = (props) => (
    <Tag.span className="btn-public" onClick={props.onChange}>
        <Tag.span className={classNames('icon', { checked: props.checked })}>
            {props.checked && <TcIconTick className="tcIcon_tick" />}
        </Tag.span>

        回复并取消隐藏
    </Tag.span>
);

BtnPublic.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func
};
