import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

import { IconZanShang, IconWePay } from 'components/IconSystem';
import './tipped-qrcode.less';

export const TippedQrcode = (props) => (
    <div className="com-tip-scan-modal content">

        <div className="content-inside">
            <a className="close-tag" onClick={props.onClose}></a>
            <header>
                <IconZanShang />
                {/* 赞赏吐槽 */}
            </header>
            <span className="wechatpay">
                <IconWePay />
                微信支付
            </span>
            <div className="qrcode">
                <QRCode size={111} level="H" value={props.url} />
            </div>

            <footer>使用微信扫描二维码完成支付</footer>
        </div>
    </div>
);

TippedQrcode.propTypes = {
    url: PropTypes.string,
    onClose: PropTypes.func
};
