/* eslint-disable camelcase */

import { useState } from 'react';
import { getApiTopicsPostsPopular, getApiV3RoadmapTopicById } from 'components/api';
import useEffectOnce from '../useEffectOnce';
import { getUrlId } from 'components/util/string';
import { TOPIC_SCENE } from 'components/constants/roadmap';
import { GetApiV3RoadmapTopicsRespData } from 'components/api/v3/roadmap/topics';

interface UseThxRoadmapUserParams {
  /** 产品ID */
  productId: string | number;
  /** 功能投票链接 */
  topicLink: string;
}
interface Data extends GetApiV3RoadmapTopicsRespData {
  post_users: {
    avatar_url: string;
    content: string;
    nick_name: string;
    user_id: string;
  }[];
}
export const useThxRoadmapUser = ({ productId, topicLink }: UseThxRoadmapUserParams) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState<Data>();
  const topicId = getUrlId(topicLink);

  useEffectOnce(() => {
    Promise.all([
      getApiV3RoadmapTopicById({ productId, topicId, scene: TOPIC_SCENE.STAT }),
      getApiTopicsPostsPopular({ productId, topicId }),
    ])
      .then(([topicDetail, postList]) => {
        setIsLoading(false);
        setData({
          ...topicDetail.data,
          post_users: Object.keys(postList.data).map(key => ({
            avatar_url: postList.data[key].avatar_url,
            content: postList.data[key].content,
            nick_name: postList.data[key].nick_name,
            user_id: postList.data[key].user_id,
          })),
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err?.message || '请求异常');
      });
  });

  return { isLoading, error, data };
};
