import React from 'react';

import './motion-number.less';

class MotionNumber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from: NaN
        };
        this.list = [];
        this.spew = [];
        for (let i = 0; i < 10; i++) {
            this.spew.push(<span key={`${i}`} ref={(ref) => this.list.push(ref)}>{i}</span>);
        }
    }

    shouldComponentUpdate(nextProps) {
        return !(nextProps.to === this.props.to);
    }

    componentDidMount() {
        setTimeout(() => {
            this.forceUpdate();
        }, 0);

    }

    render() {
        const { to } = this.props;

        const offset = this.list && this.list[to] && this.list[to].offsetTop || 0;

        return <div className="motion-number">
            <span style={{
                transform: `translateY(-${offset}px)`
            }}>
                {this.spew}
            </span>
        </div>;
    }
}

export default MotionNumber;
